// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const API_ENDPOINT_LOCAL = 'https://backend.pesat.online/api/';
const API_ENDPOINT_DEV = 'https://backend.pesat.online/api/';
const API_IMAGE = 'https://backend.pesat.online/storage/';

export const environment = {
  production: false,
  APIURL: API_ENDPOINT_LOCAL,
  APIIMAGE:API_IMAGE
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
